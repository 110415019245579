/* header */
#menu {
    background-color:  rgba(0, 0, 0, 0.9);
    z-index: 99;
    box-shadow: -1px 1px 7px 1px rgb(0 0 0 / 40%);
    position: fixed;
    width: 100vw;
    margin: auto;
    top: 0;

    .topnav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        width: 100%;
        max-width: 1400px;
        margin: auto;
        padding: 0 4rem;

        @media only screen and (max-width: 850px) {
            padding: 0 1rem;
        }

        .logo {    
            svg {
                height: 50px;

                @media only screen and (max-width: 850px) {
                    height: 2rem;
                }

                path {
                    fill: var(--white);
                }

                &:hover {
                    path {
                        fill: var(--light-orange)
                    }
                }
            }
        }

        .navlinks {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;

            a {
                text-decoration: none;
                color: var(--white);
                margin: 0;

                &:hover {
                    color: var(--light-orange); 
                }
            }

            @media screen and (max-width: 900px) {
                display: none;
            }
        }

        @media screen and (max-width: 900px) {
            height: 60px;
        }
    } 

    @media screen and (max-width: 900px) {
        background-color: var(--black);
    }
}

#mobile-menu {
    display: none;

    .mobilemenu-items {
        background-color: var(--light-orange);
        position: fixed;
        width: 100vw;
        left: 0;
        top: 60px;
        height: calc(100vh - 60px);
        padding: 30px;
        display: flex;
        flex-direction: column;
        transform: translate(-100%, 0);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

        a {  
            text-decoration: none;
            transition: color 0.3s ease;
            font-weight: normal;
            font-size: 1.15rem;
            margin: 0;
        }

        a:not(.primary) {
            color: var(--black);
            padding: 10px 0;

        }

        .primary {
            margin-top: 1rem;
        }

    }

    .menu-toggle {
        display: block;
        position: fixed;
        top: 19px;
        right: 19px;
        width: fit-content;
        z-index: 99;

        .mobilemenu-icon {
            display: block;
            width: 40px;
            height: 32px;
            position: absolute;
            cursor: pointer;
            opacity: 0; 
            z-index: 2; 
            -webkit-touch-callout: none;
        }

        span {
            display: block;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            background: var(--white);
            border-radius: 3px;
            transform-origin: 4px 0px;
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
            background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
            opacity 0.55s ease;

            &:first-child {
                transform-origin: 0% 0%;
            }

            &:nth-last-child(2) {
                transform-origin: 0% 100%;
            }
        }
    }
    
    &.is-active {
        .mobilemenu-items {
            transform: none;
        }

        span {
            opacity: 1;
            transform: rotate(45deg) translate(-2px, -1px);
        }
    
        span:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }
    
        span:nth-last-child(2) {
            transform: rotate(-45deg) translate(0, -1px);
        }
    }

    @media screen and (max-width: 900px) {
        display: block;
    }
}
