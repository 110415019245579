.contact-person__wrapper {
    display: flex;
    gap: 6rem;
    margin: 4rem 0;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        gap: 2rem;
        margin-top: 2rem;
    }
}

.contact-person {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        border-radius: 50%;
        width: 66%;
        margin-bottom: 1rem;
        filter: sepia(70%);

        @media screen and (max-width: 800px) {
            width: 70%;
            max-width: 350px;
        }
    }

    
}

.locatie-wrapper {
    margin-top: 6rem;

    @media screen and (max-width: 800px) {
        margin-top: 2rem;
    }
}