
.image-wrapper.slider {
    width: 100%;
    min-height: 400px;
    display: flex;
    gap: 1rem;

    .img-container {
        flex: 1 1 0;
        height: 400px;
        display: flex;
        border-radius: 0.5rem;
        overflow: hidden;
    }

    @media screen and (max-width: 800px) {
        display: block;
        position: relative;
        min-height: 300px;

        .img-container {
            opacity: 0;
            transition: all 4s;
            height: 300px;
            width: 100%;

            &:not(:first-child) {
                position: absolute;
                top: 0;
                left: 0;
            }

            &.active {
                opacity: 1;
            }
        } 
    }
}


