.map-wrapper {
    max-width: 100%;

    .image-wrapper {
        display: flex;
        gap: 2rem;

        img {
            width: 70%;
        }
    
        .legenda {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .item {
                display: flex;
                line-height: 1.5rem;
                margin-bottom: 2rem;

                .number {
                    font-family: "tan-kindred", cursive;
                    font-size: 1.5rem;
                    margin-right: 0.5rem;
                    color: var(--dark-green)
                }

                .place {
                    font-size: 1.5rem;
                }
            }
        }
    }
}