.info-wrapper {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-rows: auto 1fr; /* NEW */
    gap: 1rem;
    margin-bottom: 4rem;

    .div1 { grid-area: 1 / 1 / 3 / 2; }
    .div2 { grid-area: 1 / 3 / 2 / 4; }
    .div3 { grid-area: 1 / 2 / 2 / 3; }
    .div4 { grid-area: 2 / 2 / 3 / 3; }
    .div5 { grid-area: 2 / 3 / 3 / 4; }

    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-template-rows: auto 1fr; /* NEW */
     
        .div1 { grid-area: 2 / 1 / 3 / 3; }
        .div2 { grid-area: 1 / 1 / 2 / 2; }
        .div3 { grid-area: 1 / 2 / 2 / 3; }
        .div4 { grid-area: 3 / 1 / 4 / 2; }
        .div5 { grid-area: 3 / 2 / 4 / 3; }
    }

    @media only screen and (max-width: 850px) {
        padding: 0;
    }

    @media only screen and (max-width: 550px) {
        display: flex;
        flex-direction: column;
    }

    .flip-container {
        -webkit-perspective: 1000px;
        perspective: 1000px;
        cursor: pointer;
        
        &.hover .flipper {
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }
        
        .flipper {
            -webkit-transition: 1s;
            -webkit-transform-style: preserve-3d;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transition: 1s;
            position: relative;
            background: var(--white-opacity);
            padding: 1.5rem;
            border-radius: 0.5rem;
            height: 100%;
            width: 100%;

            @media only screen and (max-width: 850px) {
                padding: 1rem;
            }
        }
        
        .back {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0.5rem;
                z-index: 9;
            }
        }
        
        .back {
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }
    }
}