.button {
    padding: 0.85rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
    font-weight: bold;
    font-family: 'tan-kindred', cursive;
    font-size: .85rem;
    display: inline-block;
    width: fit-content;
    border: 2px solid;
    text-decoration: none;
    cursor: pointer;

    & + .button {
        margin-left: 1rem;
    }

    @media screen and (max-width: 800px) {
        padding: 0.65rem;
        font-size: 0.85rem;
        display: block;

        & + .button {
            margin-left: 0;
        }
    
    }
    
}

.button.primary {
	background: var(--black);        
    border-color: var(--black);
    color: var(--white);

    &.active {
        background: var(--light-orange);
        border-color: var(--light-orange);
    }
    
    &:hover {
        background: var(--light-orange);
        border-color: var(--light-orange);
    }
}

.button.primary-green {
    background: var(--dark-green);
    border-color: var(--dark-green);

    &.active,
    &:hover {
        background: var(--light-green);        
        border-color: var(--light-green);
        color: var(--white)!important;
    }
}

.button.secondary {
	background: transparent;    
    border-color: var(--black);
    color: var(--black);

    &.active,
    &:hover {
        background: var(--light-orange);
        border-color: var(--light-orange);
        color: var(--white);
    }
}

.button.link {
    text-decoration: underline;
    padding: 0;
    margin: 0;
    border: none;
    display: inline;

    &.active,
    &:hover {
        color: var(--light-orange);
    }
}

