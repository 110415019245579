body {
    background: url('../images/background.JPG');
    background-size: cover;
    background-repeat: round;
}

.layout {
    min-height: 100vh;
    overflow: scroll;
    padding: calc(4rem + 80px) 4rem 4rem;
    box-sizing: border-box;
    position: relative;
    max-width: 1400px;
    margin: auto;
}

.trademark {
    color: var(--black);
    font-size: 0.75rem;
    bottom: 1rem;
    position: absolute;
    right: 1rem;
}

@media only screen and (max-width: 850px) {
    .layout, 
    .login-overlay {
        padding: calc(2rem + 60px) 1rem;
    }
}