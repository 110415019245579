.faq {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .faq-item {
        margin: 1rem 0;

    }

    .faq-item:not(:first-child) {
        break-inside: avoid;
    }

    @media screen and (max-width: 950px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
    }
    
}
