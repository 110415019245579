:root {
--black: #171717;
--white: #fdf8ea;
--white-opacity:  rgba(253, 248, 234, 0.798);


--dark-orange: #744a1f;
--dark-green: #434e2f;
--orange: #d0902a;
--green: #758a46;
--light-orange: #e0c16b;
--light-green: #a1b36c;
--extra-light-green: #d0d493;

}

@font-face {
  font-family: "tan-kindred";
  src: url("./assets/fonts/tan-kindred.woff2") format('woff2');
}

@font-face {
  font-family: "montserrat";
  src: url("./assets/fonts/Montserrat-Regular.woff") format('woff');
}

@font-face {
  font-family: "montserrat-semi";
  src: url("./assets/fonts/Montserrat-SemiBold.woff") format('woff');
}

@font-face {
  font-family: "montserrat-thin";
  src: url("./assets/fonts/Montserrat-Thin.woff") format('woff');
}


* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'montserrat-semi', sans-serif;
  font-weight: 900;
  -webkit-text-size-adjust: 100%;
}

h1, h2, h3 {
  font-family: 'tan-kindred', cursive;
}

h1, h2, h3 {
  margin-bottom: 1rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 0.75rem;
  }
}

h1 {
  font-weight: bold;
  font-size: 3rem;
  text-transform: uppercase;

    @media screen and (max-width: 800px) {
      font-size: 2.5rem;
    }
}

h2 {
  font-weight: lighter;
  font-size: 2rem;

    @media screen and (max-width: 800px) {
      font-size: 1.75rem;
    }
}

h3 {
  font-size: 1rem;

  @media screen and (max-width: 800px) {
    font-size: 0.85rem;
  }
}

h4 {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 800px) {
    font-size: .95rem;
  }
}

p, span {
  font-family: 'montserrat';
  font-weight: 300;
  line-height: 1.85rem;
  text-align: justify;

  @media screen and (max-width: 550px) {
    font-size: .95rem;
  }
}

a {
  font-weight: 300;
  color: var(--black);
  cursor: pointer;

  &:hover {
    color: var(--light-orange);
  }
}

li {
  list-style: none;
}

input, select, textarea {
  background-color: var(--white-opacity);
}

img {
  width: 100%;
  object-fit: cover;
}


@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.m-top {
  margin-top: 1rem;
}
.m-bottom {
  margin-bottom: 1rem;
}

.nomp {
  margin: 0;
}
