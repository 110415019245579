:root {
    --slot-count: 20;
    --header-slot-count: 10;
    --slot-width: 4.5rem;
    --slot-gap: .5rem;
    --timetable-padding: 175px;
    --timetable-padding-mobile: 116px;
    --timeline-left-basis: calc(var(--timetable-padding) + 1rem);
    --timeline-left-basis-mobile: calc(var(--timetable-padding-mobile) + 1rem);
}

.timetable-wrapper {
    position: relative;
    overflow-x: auto;
    background: var(--white-opacity);
    border-radius: 0.5rem;
    padding: 1rem;    
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
      }
}

.timeline {
    position: absolute;
    height: calc(100% + 1rem);
    top: -1rem;
    width: 3px;
    background: var(--light-orange);
    z-index: 10;
    margin-left: var(--timeline-left-basis);
    left: 0;

    @media screen and (max-width: 800px) {
        margin-left: var(--timeline-left-basis-mobile);
    }
}

.timetable-header-slot:nth-child(odd) {
    width: calc(200% + var(--slot-gap));
    z-index: 2;
}

.timetable-header-slot:last-child {
    width: 100%;
}

.timetable-header {
    display: grid;
    column-gap: calc(var(--slot-gap));
    position: sticky;
    top: 0;
    margin-bottom: 0.5rem;
    margin-left: var(--timetable-padding);
    z-index: 2;

    @media screen and (max-width: 800px) {
        margin-left: var(--timetable-padding-mobile);
        // padding-left: 0;
    }

    div {
        background-color: var(--black);
        color: var(--white);
        padding: 0.25rem;
        font-weight: bold;
        text-align: center;
    }
}

.timetable-row {
    position: relative;
    padding-left: var(--timetable-padding);

    @media screen and (max-width: 800px) {
        padding-left: var(--timetable-padding-mobile);
    }
}

.timetable-image {
    position: absolute;
    height: 100px;
    width: 150px;
    object-fit: cover;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    @media screen and (max-width: 800px) {
        width: 100px;
    }
}

.timetable-info {
    .title {
        display: grid;
        column-gap: var(--slot-gap);
        padding-top: 0.75rem;
        width: 100%;
        border-top: 1px solid var(--black);

        h3 {
            position: sticky;
            display: inline;
            left: 0;
            grid-column: 1 / 5;
            margin: 0;
            font-weight: bold;
            font-size: 1rem;
        }
    }

    .timetable {
        display: grid;
        column-gap: var(--slot-gap);
        font-weight: bold;

        .timetable-event {
            background-color: var(--orange);
            padding: 0.75rem 0.25rem 0.75rem 1.5rem;
            margin: 0.5rem 0;
            position: relative;
            color: var(--color-500);
            grid-row: 1;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                background-color: var(--dark-orange);
                opacity: .7;
                width: 1rem;
                z-index: 1;
            }

            &::after {
                content: attr(data-times);
                display: block;
                font-size: .75rem;
                font-weight: normal;
            }

            &.afterparty {
                grid-column: t-0000 / last;
                margin-right: var(--slot-gap);
            }

            &[data-times="00:30 - 01:00"] {
                margin-right: var(--slot-gap);
            }
        }
    }
}

.desktop-text {
    display: inline;
}

@media screen and (max-width: 800px) {

    .desktop-text {
        display: none;
    }
}


/* scrollbar */

.timetable-wrapper::-webkit-scrollbar {
    width: 0.75rem;
  }
  
  .timetable-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow:  0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .timetable-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--pink);
    outline: 1px solid var(--pink);
    cursor: pointer;
  }
  
  .timetable-wrapper::-webkit-scrollbar:vertical {
    display: none;
  }