@import "../../index.scss";

.login-overlay {
    background: rgba(0,0,0,0.6);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99999;

    .login-wrapper {
        @include centerer;
        padding: 25px;
        width: 350px;
        max-width: 100%;
        background: rgba(255,255,255,0.6);
        border-radius: 25px;

        label {
            margin-bottom: 1rem;
            text-align: center;
        }

        input {
            width: 50%;
            margin: auto;
        }
    }

    .form__label-wrapper {
        position: reative;
    }

}