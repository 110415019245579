.form {
    margin-top: 1.5rem;
}

.form__label-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    max-width: 600px;
}

.form__label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

input, 
select, 
textarea {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    color: #555;
    width: 600px;
    max-width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
}

select {
    width: 300px;

    @media screen and (max-width: 600px) {
        width: 100%;
    }

    /* reset */
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    /* chevron */
    background-image:
        linear-gradient(45deg, transparent 50%, var(--light-green) 50%),
        linear-gradient(135deg, var(--light-green) 50%, transparent 50%),
        linear-gradient(to right, var(--light-green), var(--light-green));
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;

    &:focus {
        background-image:
            linear-gradient(45deg, var(--dark-green) 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, var(--dark-green) 50%),
            linear-gradient(to right, var(--light-green), var(--light-green));
        background-position:
            calc(100% - 15px) 1em,
            calc(100% - 20px) 1em,
            calc(100% - 2.5em) 0.5em;
    }
}

textarea {
    height: 75px;
    resize: none;
}