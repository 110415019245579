.button-wrapper {
    display: flex;
    gap: 1rem;
}

.map-button {
    margin-top: 2rem;
    
    a {
        color: var(--black);
        font-size: 0.75rem;
    }
}