@import "../../index.scss";

.home {
    h1 {
        color: var(--black);
    }
    
    h2 {        
        svg {
            height: 3rem;
            margin-right: 0.5rem;
        }
    }
    
    h3 {
        font-family: montserrat;
        font-weight: normal;
        font-size: 2rem;
        text-align: right;
    }

    .home-wrapper {

        .text-wrapper {
            display: flex;
            justify-content: space-between;
            padding-bottom: 3rem;

            @media screen and (max-width: 900px) {
                flex-direction: column;
                padding-bottom: 2rem;

                h1 {
                    display: none;
                }

                h2 {
                    margin-bottom: 0.5rem;
                    font-size: 2.25rem;

                    svg {
                        height: 2.25rem;
                        margin-right: 0.5rem;
                    }
                }

                h3 {
                    text-align: left;
                    font-size: 2rem;
                }
            }
        }

        @media screen and (max-width: 900px) {
            margin: 2rem 0;

        }

        .countdown {
            text-align: center;
            padding-top: 2rem;
        }
    }

    .intro-wrapper {
        width: 75%;
        min-width: 800px;
        margin: auto;
        padding: 8rem 0;
        display: flex;
        gap: 3rem;

        .text-wrapper {
            width: 50%;
            text-align: justify;
        }

        .image-wrapper {
            width: 50%;
            border-radius: 0.5rem;
            overflow: hidden;

            .img-container {
                height: 100%;
            }

        }

        @media screen and (max-width: 900px) {
            flex-direction: column;
            width: 100%;
            min-width: initial;
            padding: 3rem 0 0;
            gap: 1rem;

            .text-wrapper, 
            .image-wrapper {
                width: initial;
            }

        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}